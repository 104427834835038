<template>
  <footer class="footer-area footer-default">
    <div class="footer-wrapper">
      <v-row align="end" class="row--0">
        <v-col md="6" sm="12" cols="12">
          <div class="footer-left">
            <div class="inner">
              <span>¿Todo en orden?</span>
              <h2>
                Vamos con <br />
                todo.
              </h2>
              <router-link class="rn-button-style--2" to="/contacto"
                ><span>¿Nos tomamos un cafe?</span></router-link
              >
            </div>
          </div>
        </v-col>
        <!-- End .col -->
        <v-col md="6" sm="12" cols="12">
          <div class="footer-right" data-black-overlay="6">
            <v-row>
              <!-- Start Single Widget -->
              <v-col lg="6" sm="6" cols="12">
                <div class="footer-widget">
                  <h4>Links directos</h4>
                  <ul class="ft-link">
                    <li v-for="(nav, i) in navList" :key="i">
                      <router-link
                        v-if="
                          nav.navItem != 'Política de Privacidad y Datos Personales' &&
                          nav.navItem != 'Cultura ⚡️inflow'
                        "
                        :to="nav.to"
                        >{{ nav.navItem }}</router-link
                      >
                      <a
                        v-if="nav.navItem == 'Política de Privacidad y Datos Personales'"
                        :href="nav.to"
                        target="_blank"
                      >
                        {{ nav.navItem }}
                      </a>
                      <a
                        v-if="nav.navItem == 'Cultura ⚡️inflow'"
                        :href="nav.to"
                        target="_blank"
                      >
                        {{ nav.navItem }}
                      </a>
                    </li>
                  </ul>
                </div>
              </v-col>
              <!-- End Single Widget  -->
              <!-- Start Single Widget -->
              <v-col lg="6" sm="6" cols="12" class="mt_mobile--30">
                <div class="footer-widget">
                  <h4>Saludanos</h4>
                  <ul class="ft-link">
                    <li v-for="(mail, i) in mailList" :key="i">
                      <a :href="mail.to">{{ mail.mailItem }}</a>
                    </li>
                  </ul>

                  <div class="social-share-inner">
                    <ul
                      class="social-share social-style--2 d-flex justify-content-start liststyle mt--15"
                    >
                      <li v-for="(social, i) in socialList" :key="i">
                        <a :href="social.url" target="_blank">
                          <i class="fab" :class="social.icon"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </v-col>
              <!-- End Single Widget  -->

              <v-col lg="12">
                <div class="copyright-text">
                  <p>
                    Copyright © {{ new Date().getFullYear() }} Inflow. Todos los
                    derechos reservados.
                  </p>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-col>
        <!-- End .col -->
      </v-row>
    </div>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      socialList: [
        {
          icon: "fa-facebook-f",
          url: "https://www.facebook.com/inflow.cl/",
        },
        {
          icon: "fa-linkedin-in",
          url: "https://www.linkedin.com/company/inflow-cl/",
        },
        {
          icon: "fa-instagram-square",
          url: "https://www.instagram.com/inflowchile/",
        },
      ],
      navList: [
        {
          navItem: `Cultura ⚡️inflow`,
          to: "https://inflow-cl.notion.site/Cultura-Inflow-fc0f30ae4ca04fe09f2aab3cafeb4e7f?pvs=4",
        },
        {
          navItem: `Blog`,
          to: "/blog",
        },
        {
          navItem: `Contacto`,
          to: "/contacto",
        },
        {
          navItem: "Política de Privacidad y Datos Personales",
          to: "https://inflow-cl.notion.site/Poli-tica-de-Privacidad-y-Datos-Personales-7f543156387f48d0bd802c73a9c64192?pvs=4",
        },
      ],
      mailList: [
        {
          mailItem: "contacto@inflow.cl",
          to: "mailto:contacto@inflow.cl",
        },
      ],
    };
  },
};
</script>
